<template>
  <div class="w-full">
    <div class="flex justify-between">
      <div
        class="w-9/12 px-3 py-2 bg-black -space-y-2 text-white italic"
        @click="goToMain"
      >
        <div class="flex justify-between">
          <div>
            <span class="ml-1">{{ taxi?.code || "—" }}</span>
            <span class="ml-1">-- {{ user?.dni || "—" }}</span>
            <span class="ml-1">-- {{ user?.name || "—" }}</span>
          </div>
        </div>
      </div>

      <div
        class="w-1/12 grow py-2 flex flex-col justify-between items-center bg-black"
      >
        <div class="text-white">{{ date }}</div>
      </div>
    </div>

    <div class="flex justify-between">
      <div
        class="w-9/12 px-3 py-2 bg-black -space-y-2 text-white italic"
        @click="goToMain"
      >
        <div class="flex justify-between">
          <div>
            <div>
              <span class="text-sm">Inicio:</span>
              <span class="ml-1">{{ scheduleFrom }}</span>
              <span class="text-sm"> -- </span>
              <span class="text-sm">Fin:</span>
              <span class="ml-1">{{ scheduleTo }}</span>
            </div>
          </div>
        </div>
      </div>

      <div
        class="w-1/12 grow py-2 flex flex-col justify-between items-center bg-black"
      >
        <div class="text-white">{{ time }}</div>
      </div>
    </div>
    <div class="flex overflow-x-hidden" v-if="pendingServicesExist">
      <div
        :class="[zonesServices.length > 6 ? 'carrusel-container' : '', 'flex']"
      >
        <!-- <div class="text-sm w-full">
          <translation value="notifications.zonesService" />: 
        </div> -->
        <span
          class="inline-flex flex-shrink-0 items-center rounded-md px-2 py-1 text-xs font-medium bg-gray-50 ring-1 ring-inset ring-gray-500/10 m-1"
          v-for="zs in zonesServices"
          v-bind:key="zs.code"
          :style="{ backgroundColor: zs.color }"
        >
          {{ zs.code.substring(0, 2) }} ({{ zs.number }})
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { LogoutIcon } from "@heroicons/vue/outline";
import moment from "moment";
import { mapState } from "vuex";
import Connection from "@/ws/Connection";
import Modal from "@/modals/Modal";
import Translate from "@/translations/Translate";
import InlineSvg from "vue-inline-svg";
import { App } from "@capacitor/app";
import { api } from "@/boot/axios";

export default {
  name: "MainHeader",
  components: {
    InlineSvg,
    LogoutIcon,
  },
  props: ["scheduleFrom", "scheduleTo"],
  data() {
    return {
      appVersion: null,
      intervals: {
        dateTime: null,
        zonesServices: null,
      },
      zonesServices: [],
    };
  },
  computed: {
    ...mapState(["user", "taxi", "serverTime", "serviceTaxi", "station"]),
    time() {
      return moment(this.serverTime).locale("ca").format("HH:mm");
    },
    date() {
      return moment(this.serverTime).locale("ca").format("DD/MM/YYYY");
    },
    pendingServicesExist() {
      return this.zonesServices?.length > 0;
    },
  },
  mounted() {
    App.getInfo().then((info) => {
      this.appVersion = info.version;
    });
    this.intervals.zonesServices = setInterval(() => {
      api
        .get("/zone/services")
        .then((response) => {
          this.zonesServices = response.data;
        })
        .catch((e) => {});
    }, 2000);
  },
  beforeUnmount() {
    if (this.intervals.zonesServices) {
      clearInterval(this.intervals.zonesServices);
    }
  },
  methods: {
    goToMain() {
      if (this.$route.name !== "driver-main") {
        this.$router.replace({ name: "driver-main" });
      }
    },
    toggleDisconnect() {
      if (!this.taxi) return;

      if (this.taxi.state !== "offline") {
        Connection.send({
          type: "Driver",
          action: "State",
          state: "offline",
        });
      } else {
        Connection.send({
          type: "Driver",
          action: "State",
          state: "online",
        });
      }
    },

    logout() {
      Modal.openConfirmModal({
        title: Translate.value("txt.confirm-logout-title").text,
        text: Translate.value("txt.confirm-logout-text").text,
      })
        .then(() => {
          this.$router.replace({ name: "logout" });
        })
        .catch(() => {});
    },
  },
};
</script>
