<template>
  <div
    class="w-full h-full flex justify-center overflow-y-scroll"
    :class="[
      ((taxiState === 'online' || taxiState === 'busy') && 'bg-gray-300') ||
        (taxiState === 'disabled' ? 'bg-amber-400' : 'bg-red-400'),
    ]"
  >
    <template v-if="taxiState === 'disabled'">
      <div
        class="h-32 mt-6 w-full flex flex-col justify-center items-center text-gray-800 font-semibold text-2xl"
      >
        <ExclamationIcon class="w-10 h-10" />
        <translation value="taxis.disabled" />
      </div>
    </template>
    <template v-else-if="taxiState === 'online' || taxiState === 'busy'">
      <div
        v-if="
          !serviceTaxi ||
          (taxiState == 'busy' && !isServiceMine) ||
          status === 'offline'
        "
        class="h-32 gap-1 text-gray-800 font-semibold text-3xl"
      >
        <div v-if="taxiState != 'busy'">
          <div class="mt-4 text-center justify-center">
            <div class="w-full">
              <ClockIcon class="w-10 h-10 mx-auto" />
            </div>
            <div>
              <translation value="services.awaiting" />
            </div>
          </div>
          <div class="flex mt-4 text-center justify-center">
            <service-info-notifications
              :taxiConfig="taxiConfig"
            ></service-info-notifications>
          </div>
          <!--
          <div class="flex mt-4 text-center justify-center">
            <system-notifications></system-notifications>
          </div>
          <div class="flex mt-4 text-center justify-center">
            <service-notifications></service-notifications>
          </div>
          -->
        </div>
        <div v-else>
          <service-zones />
        </div>
      </div>

      <service-tile-accepted :taxiConfig="taxiConfig" v-else-if="hasAccepted" />

      <service-tile-assigned v-else :taxiConfig="taxiConfig" />
    </template>

    <template v-else>
      <div
        class="h-32 mt-6 flex flex-col justify-center items-center text-gray-800 font-semibold text-2xl"
      >
        <div class="flex items-center gap-1">
          <ExclamationIcon class="w-8 h-8" />
          <translation value="services.offline" />
        </div>

        <div class="mt-4">
          <button
            class="flex items-center font-semibold px-4 py-2 bg-green-700 rounded-md"
            @click="connect"
          >
            <translation value="button.connect" />
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { ClockIcon, ExclamationIcon } from "@heroicons/vue/outline";
import Translation from "@/translations/Translation.vue";
import { mapGetters, mapState } from "vuex";
import ServiceTileAssigned from "./ServiceTileAssigned.vue";
import ServiceTileAccepted from "./ServiceTileAccepted.vue";
import ServiceNotifications from "./ServiceNotifications.vue";
import ServiceInfoNotifications from "./ServiceInfoNotifications.vue";
import SystemNotifications from "./SystemNotifications.vue";
import ServiceZones from "./ServiceZones.vue";
import Connection from "@/ws/Connection";
import { NativeAudio } from "@capacitor-community/native-audio";
import Tracking from "@/tracking/Tracking";

export default {
  name: "ServiceTile",
  components: {
    ClockIcon,
    ExclamationIcon,
    Translation,
    ServiceTileAssigned,
    ServiceTileAccepted,
    ServiceNotifications,
    ServiceInfoNotifications,
    SystemNotifications,
    ServiceZones,
    NativeAudio,
  },
  props: ["taxiConfig"],
  data() {
    return {};
  },
  watch: {
    serviceTaxi: {
      deep: true,
      handler(serviceTaxi) {
        // if (serviceTaxi?.assignment?.status == "assigned") {
        //   if (this.taxiConfig.sound == 1) {
        //     this.newService();
        //   }
        // }
      },
    },
  },
  computed: {
    ...mapState({
      taxi: (state) => state.taxi,
      serviceTaxi: (state) => {
        return state.serviceTaxi;
      },
    }),
    ...mapGetters(["isOffline"]),

    taxiState() {
      return !this.isOffline && this.taxi?.state;
    },

    service() {
      return this.serviceTaxi?.service;
    },

    assignment() {
      return this.serviceTaxi?.assignment;
    },

    status() {
      return this.serviceTaxi?.assignment?.status;
    },

    hasAccepted() {
      return ["accepted", "pickedup", "droppedoff"].includes(this.status);
    },

    isServiceMine() {
      return this.serviceTaxi?.assignment?.taxiId === this.taxi.id;
    },
  },
  methods: {
    connect() {
      if (this.isOffline) {
        Connection.connect();
      }

      Tracking.current();
      Connection.send({
        type: "Driver",
        action: "State",
        state: "online",
      });
    },
    busy() {
      if (this.isOffline) {
        Connection.connect();
      }

      Connection.send({
        type: "Driver",
        action: "State",
        state: "busy",
      });
    },
    free() {
      if (this.isOffline) {
        Connection.connect();
      }

      Connection.send({
        type: "Driver",
        action: "State",
        state: "online",
      });
    },
    newService() {
      NativeAudio.play({
        assetId: "beep",
        // time: 6.0 - seek time
      });
    },
  },
};
</script>
<style>
.carousel__slide {
  padding: 5px;
}

.carousel__icon {
  display: none !important;
}

.carousel__pagination {
  display: none !important;
}

.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 10s !important;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
  opacity: 1;
  transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1.1);
}
</style>
