import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/Auth/Login.vue";
import Logout from "../views/Auth/Logout.vue";
//import Register from "../views/Auth/Register.vue";
import MapIndex from "../views/Admin/Map/Index.vue";
import MunicipalitiesIndex from "../views/Admin/Municipalities/Index.vue";
import MunicipalitiesEdit from "../views/Admin/Municipalities/Edit.vue";
import AreasIndex from "../views/Admin/Areas/Index.vue";
import AreasEdit from "../views/Admin/Areas/Edit.vue";
import AreasLogIndex from "../views/Admin/AreasLog/Index.vue";
import ZonesIndex from "../views/Admin/Zones/Index.vue";
import ZonesEdit from "../views/Admin/Zones/Edit.vue";
import SectorsIndex from "../views/Admin/Sectors/Index.vue";
import SectorsEdit from "../views/Admin/Sectors/Edit.vue";
import StationsIndex from "../views/Admin/Stations/Index.vue";
import StationsEdit from "../views/Admin/Stations/Edit.vue";
import TaxisIndex from "../views/Admin/Taxis/Index.vue";
import TaxisEdit from "../views/Admin/Taxis/Edit.vue";
import TaxisLog from "../views/Admin/TaxisLog/Index.vue";
import ServicesIndex from "../views/Admin/Services/Index.vue";
import ServicesShow from "../views/Admin/Services/Show.vue";
import ServicesEdit from "../views/Admin/Services/Edit.vue";
import ServicesFutur from "../views/Admin/Services/Futur.vue";
import ServicesLogIndex from "../views/Admin/ServicesLog/Index.vue";
// import ServicesEdit from "../views/Admin/Services/Edit.vue";
import UsersIndex from "../views/Admin/Users/Index.vue";
import UsersEdit from "../views/Admin/Users/Edit.vue";
import PlacesIndex from "../views/Admin/Places/Index.vue";
import DriversIndex from "../views/Admin/Drivers/Index.vue";
import DriversEdit from "../views/Admin/Drivers/Edit.vue";
import ConfigIndex from "../views/Admin/Config/Index.vue";
import StatusIndex from "../views/Admin/Status/Index.vue";
import ClientTypesIndex from "../views/Admin/ClientTypes/Index.vue";
import ClientTypesEdit from "../views/Admin/ClientTypes/Edit.vue";
import ClientsIndex from "../views/Admin/Clients/Index.vue";
import ClientsEdit from "../views/Admin/Clients/Edit.vue";
import Reserva from "../views/Admin/Reserva.vue";
import SituationsIndex from "../views/Admin/Situations/Index.vue";

import DriverMain from "../views/Driver/Main/Index.vue";
import DriverMap from "../views/Driver/Map/Index.vue";
import DriverMapService from "../views/Driver/Main/components/ServiceMap.vue";
import DriverDestinationService from "../views/Driver/Main/components/ServiceDestination.vue";
import DriverMapDestinationService from "../views/Driver/Main/components/ServiceMapDestination.vue";
import DriverCantadoService from "../views/Driver/Main/components/ServiceCantado.vue";
import DriverStations from "../views/Driver/Stations/Index.vue";
import DriverConfig from "../views/Driver/Config/Index.vue";
import ServicesCurrent from "../views/Driver/Services/Current.vue";
import ClientMapIndex from "../views/Client/Main/Index.vue";
import ClientReserva from "../views/Client/Reserva.vue";
import ClientServicesIndex from "../views/Client/Services/Index.vue";
import ClientServicesHistory from "../views/Client/Services/History.vue";
import ClientServicesShow from "../views/Client/Services/Show.vue";

import ClientConfigIndex from "../views/Client/Config/Edit.vue";
import PageNotFound from "../views/PageNotFound.vue";

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
  },
  // {
  //   path: "/register",
  //   name: "register",
  //   component: Register,
  // },
  {
    path: "/home",
    name: "home",
    component: MapIndex,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/municipalities",
    name: "municipalities-index",
    component: MunicipalitiesIndex,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/municipalities/create",
    name: "municipalities-create",
    component: MunicipalitiesEdit,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/municipalities/:id",
    name: "municipalities-edit",
    component: MunicipalitiesEdit,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/areas",
    name: "areas-index",
    component: AreasIndex,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/areas/log",
    name: "areas-log",
    component: AreasLogIndex,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/areas/create",
    name: "areas-create",
    component: AreasEdit,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/areas/:id",
    name: "areas-edit",
    component: AreasEdit,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/zones",
    name: "zones-index",
    component: ZonesIndex,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/zones/create",
    name: "zones-create",
    component: ZonesEdit,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/zones/:id",
    name: "zones-edit",
    component: ZonesEdit,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/sectors",
    name: "sectors-index",
    component: SectorsIndex,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/sectors/create",
    name: "sectors-create",
    component: SectorsEdit,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/sectors/:id",
    name: "sectors-edit",
    component: SectorsEdit,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/stations",
    name: "stations-index",
    component: StationsIndex,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/stations/create",
    name: "stations-create",
    component: StationsEdit,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/stations/:id",
    name: "stations-edit",
    component: StationsEdit,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/taxis",
    name: "taxis-index",
    component: TaxisIndex,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/taxis/create",
    name: "taxis-create",
    component: TaxisEdit,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/taxis/:id",
    name: "taxis-edit",
    component: TaxisEdit,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/taxis/log",
    name: "taxis-log",
    component: TaxisLog,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/services",
    name: "services-index",
    component: ServicesIndex,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/services/futur",
    name: "services-futur",
    component: ServicesFutur,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/services/:id",
    name: "services-show",
    component: ServicesShow,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/services/:id/edit",
    name: "services-edit",
    component: ServicesEdit,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/services/log",
    name: "services-log-show",
    component: ServicesLogIndex,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/status/:type?",
    name: "status-index",
    component: StatusIndex,
    meta: { allowedRoles: ["admin"] },
  },
  // {
  //   path: "/services/:id/edit",
  //   name: "services-edit",
  //   component: ServicesEdit,
  //   meta: { allowedRoles: ["admin"] },
  // },
  {
    path: "/drivers",
    name: "drivers-index",
    component: DriversIndex,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/drivers/create",
    name: "drivers-create",
    component: DriversEdit,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/drivers/:id",
    name: "drivers-edit",
    component: DriversEdit,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/users",
    name: "users-index",
    component: UsersIndex,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/users/create",
    name: "users-create",
    component: UsersEdit,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/users/:id",
    name: "users-edit",
    component: UsersEdit,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/reserva",
    name: "reserva",
    component: Reserva,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/places",
    name: "places-index",
    component: PlacesIndex,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/config",
    name: "config-index",
    component: ConfigIndex,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/client-types",
    name: "client-types-index",
    component: ClientTypesIndex,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/client-types/create",
    name: "client-types-create",
    component: ClientTypesEdit,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/client-types/:id",
    name: "client-types-edit",
    component: ClientTypesEdit,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/clients",
    name: "clients-index",
    component: ClientsIndex,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/clients/create",
    name: "clients-create",
    component: ClientsEdit,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/clients/:id",
    name: "clients-edit",
    component: ClientsEdit,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/situation",
    name: "situation",
    component: SituationsIndex,
    meta: { allowedRoles: ["admin"] },
  },
  {
    path: "/driver",
    name: "driver-main",
    component: DriverMain,
    meta: { allowedRoles: ["driver"] },
  },
  {
    path: "/driver/map",
    name: "driver-map",
    component: DriverMap,
    meta: { allowedRoles: ["driver"] },
  },
  {
    path: "/driver/mapservice",
    name: "driver-map-service",
    component: DriverMapService,
    meta: { allowedRoles: ["driver"] },
  },
  {
    path: "/driver/destination",
    name: "driver-destination-service",
    component: DriverDestinationService,
    meta: { allowedRoles: ["driver"] },
  },
  {
    path: "/driver/destinationmapservice",
    name: "driver-destination-mapservice",
    component: DriverMapDestinationService,
    meta: { allowedRoles: ["driver"] },
  },
  {
    path: "/driver/cantado",
    name: "driver-cantado",
    component: DriverCantadoService,
    meta: { allowedRoles: ["driver"] },
  },
  {
    path: "/driver/stations",
    name: "driver-stations",
    component: DriverStations,
    meta: { allowedRoles: ["driver"] },
  },
  {
    path: "/driver/services/current",
    name: "driver-services-current",
    component: ServicesCurrent,
    meta: { allowedRoles: ["driver"] },
  },
  {
    path: "/driver/config",
    name: "driver-config",
    component: DriverConfig,
    meta: { allowedRoles: ["driver"] },
  },
  {
    path: "/client-main",
    name: "client-main",
    component: ClientMapIndex,
    meta: { allowedRoles: ["client"] },
  },
  {
    path: "/client-reserva",
    name: "client-reserva",
    component: ClientReserva,
    meta: { allowedRoles: ["client"] },
  },
  {
    path: "/client-services",
    name: "client-services-index",
    component: ClientServicesIndex,
    meta: { allowedRoles: ["client"] },
  },
  {
    path: "/client-services-history",
    name: "client-services-history",
    component: ClientServicesHistory,
    meta: { allowedRoles: ["client"] },
  },
  {
    path: "/client-services-show:id",
    name: "client-services-show",
    component: ClientServicesShow,
    meta: { allowedRoles: ["client"] },
  },
  {
    path: "/client-config",
    name: "client-config-index",
    component: ClientConfigIndex,
    meta: { allowedRoles: ["client"] },
  },
  {
    path: "/:pathMatch(.*)*",
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
