<template>
  <layout-driver :hideHeader="true">
    <div class="w-full h-full flex flex-col">
      <main-header :scheduleFrom="scheduleFrom" :scheduleTo="scheduleTo" />
      <div class="grow flex flex-col justify-center items-center">
        <div class="w-full h-full mt-1 mb-4 bg-gray-600">
          <service-tile :taxiConfig="taxiConfig" />
        </div>

        <div class="w-[98vw] h-full min-h-[60px] max-h-[80px]">
          <service-busy />
        </div>

        <div
          class="w-[98vw] h-[98%] min-h-[60px] max-h-[80px] mb-1 mt-1 p-px space-y-px text-2xl bg-black"
        >
          <div class="h-full flex gap-px">
            <router-link
              :to="{ name: 'driver-map' }"
              class="w-full h-full flex justify-center items-center gap-1 text-gray-800 font-semibold bg-yellow-400 rounded-md"
            >
              <div><MapIcon class="w-8 h-8" /></div>
              <translation value="generic.mapa" />
            </router-link>

            <router-link
              v-if="taxi?.state !== 'disabled'"
              :to="{ name: 'driver-cantado' }"
              class="w-full h-full flex justify-center items-center gap-1 text-gray-800 font-semibold bg-yellow-400 rounded-md"
            >
              <div><SpeakerphoneIcon class="w-8 h-8" /></div>
              Cantar
            </router-link>

            <router-link
              :to="{ name: 'driver-config' }"
              class="w-full h-full flex items-center justify-center gap-1 text-gray-800 font-semibold bg-yellow-400 rounded-md"
            >
              <div><CogIcon class="w-8 h-8" /></div>
              <translation value="nav.config" />
            </router-link>
          </div>
        </div>
        <div class="w-[98vw] mt-auto">
          <router-link :to="{ name: 'driver-stations' }">
            <service-stations
              :taxiConfig="taxiConfig"
              @loadTaxiConfig="loadTaxiConfig"
            />
          </router-link>
        </div>
      </div>
    </div>
  </layout-driver>
</template>

<script>
import {
  ClockIcon,
  CogIcon,
  MapIcon,
  SpeakerphoneIcon,
  TicketIcon,
} from "@heroicons/vue/outline";
import LayoutDriver from "../LayoutDriver.vue";
import MainHeader from "./components/MainHeader.vue";
import ServiceTile from "./components/ServiceTile.vue";
import ServiceBusy from "./components/ServiceBusy.vue";
import ServiceStations from "./components/ServiceStations.vue";
import { mapState } from "vuex";
import { useToast } from "vue-toastification";
import Translation from "@/translations/Translation.vue";
import { api } from "@/boot/axios";
import moment from "moment";
import store from "@/store";

export default {
  name: "DriverIndex",
  components: {
    ClockIcon,
    CogIcon,
    MapIcon,
    SpeakerphoneIcon,
    TicketIcon,
    LayoutDriver,
    ServiceTile,
    ServiceBusy,
    ServiceStations,
    MainHeader,
    Translation,
  },
  data() {
    return {
      taxiConfig: {},
      scheduleFrom: null,
      scheduleTo: null,
    };
  },

  setup() {
    return {
      toast: useToast(),
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      taxi: (state) => state.taxi,
    }),
  },
  created() {
    this.loadTaxiConfig();
    this.interval = setInterval(() => {
      api
        .get("/zone/services")
        .then((response) => {
          this.zonesServices = response.data;
        })
        .catch((e) => {});
    }, 10000);
  },
  beforeUnmount() {
    if (this.interval) clearInterval(this.interval);
  },
  methods: {
    loadTaxiConfig() {
      api
        .get("/taxiconfig", { params: { taxiId: this.taxi?.id } })
        .then((response) => {
          store.commit("setConfig", response.data);
          this.taxiConfig = response.data;
          this.scheduleFrom = moment(response.data.scheduleFrom).format(
            "DD/MM HH:mm",
          );
          this.scheduleTo = moment(response.data.scheduleTo).format(
            "DD/MM HH:mm",
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
