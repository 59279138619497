<template>
  <div class="w-screen h-screen flex items-center justify-center bg-taxis-blue">
    <div class="p-8 w-full max-w-sm shadow-lg rounded-lg bg-white">
      <form
        class="w-full flex flex-col justify-start items-center"
        @submit.prevent="login"
      >
        <img class="h-16" src="@/assets/logo.png" alt="logo" />

        <div class="mt-6 w-full space-y-2">
          <label class="block w-full">
            <div>
              <translation value="Email" />
            </div>
            <htc-input-field
              class="w-full"
              type="email"
              v-model:value.trim="email"
              required
            />
          </label>

          <label class="block w-full">
            <div>
              <translation value="Password" />
            </div>
            <htc-input-field
              class="w-full"
              type="password"
              v-model:value="password"
              required
            />
          </label>

          <label v-if="platform !== 'web'" class="mt-3 flex items-center gap-2">
            <input
              type="checkbox"
              v-model="remember"
              class="accent-taxis-orange border-gray-300 rounded-sm shadow-sm focus:ring-0 focus:border-taxis-orange"
            />
            <span>
              <translation value="form.remember" />
            </span>
          </label>
        </div>

        <div class="mt-8 w-full flex justify-end">
          <button
            type="submit"
            class="px-5 py-1 text-sm font-semibold rounded-md shadow-sm border outline-none ring-taxis-orange/25 border-transparent bg-blue-100 text-taxis-blue focus:text-taxis-orange focus:bg-transparent focus:ring focus:border-taxis-orange"
          >
            <translation value="Login" />
          </button>
          <!-- <router-link :to="{ name: 'register' }">
            <button
              type="submit"
              class="ml-2 px-5 py-1 text-sm font-semibold rounded-md shadow-sm border outline-none ring-taxis-orange/25 border-transparent bg-blue-100 text-taxis-blue focus:text-taxis-orange focus:bg-transparent focus:ring focus:border-taxis-orange"
            >
              <translation value="txt.Registre" />
            </button>
          </router-link> -->
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import HtcInputField from "@/components/HtcInputField.vue";
import HtcButton from "@/components/HtcButton.vue";
import Translation from "@/translations/Translation.vue";
import { api } from "@/boot/axios";
import { useToast } from "vue-toastification";
import { Capacitor } from "@capacitor/core";
import Connection from "@/ws/Connection";
import { storage } from "@/store";
import Tracking from "@/tracking/Tracking";

export default {
  name: "Login",
  components: {
    HtcInputField,
    HtcButton,
    Translation,
  },
  setup() {
    return {
      toast: useToast(),
    };
  },
  data() {
    return {
      email: "",
      password: "",
      remember: false,
      platform: Capacitor.getPlatform(),
    };
  },
  created() {
    if (this.$store.state.token) {
      if (this.$store.state.isAdmin) {
        this.$router.replace({ name: "home" });
      } else {
        if (this.$store.state?.isClient) {
          this.$router.replace({ name: "client-main" });
        } else {
          this.$router.replace({ name: "driver-main" });
        }
      }
    }

    const login = storage.getItem("login");

    if (login && this.platform !== "web") {
      try {
        const decoded = atob(login);
        this.email = decoded.split(":")[0];
        this.password = decoded.split(":")[1];
        this.remember = true;
      } catch (error) {}
    }
  },
  methods: {
    login() {
      api
        .post("/auth", {
          email: this.email,
          password: this.password,
          platform: this.platform,
        })
        .then(
          (response) => {
            if (this.remember) {
              storage.setItem("login", btoa(this.email + ":" + this.password));
            } else {
              storage.removeItem("login");
            }

            const isAdmin = response.data.user?.type?.endsWith("admin");
            const isClient = response.data.user?.type?.endsWith("client");

            if (isAdmin && this.platform !== "web") {
              this.toast.error("Usuari incompatible.");
              this.$store.commit("token", null);
              this.$store.commit("user", null);
              this.$store.commit("isAdmin", null);
              this.$store.commit("isClient", null);
              return;
            }

            sessionStorage.userId = response.data.user.id;
            sessionStorage.clientId = response.data.user.clientId ?? null;
            this.$store.commit("user", response.data.user);
            this.$store.commit("token", response.data.token);
            this.$store.commit("isAdmin", isAdmin);
            this.$store.commit("isClient", isClient);
            Connection.connect();

            if (isAdmin) {
              this.$router.replace({ name: "home" });
            } else {
              if (isClient) {
                this.$router.replace({ name: "client-main" });
              } else {
                this.$router.replace({ name: "driver-main" });
              }
            }

            if (this.platform !== "web") {
              Tracking.current();
            }
          },
          (reject) => {
            if (reject.response.data.code == 4) {
              this.toast.error(
                "No es pot llogar. Ja hi ha un usuari amb aquest taxi",
              );
            } else {
              this.toast.error("No s'ha pogut iniciar sessió.");
            }
          },
        )
        .catch((e) => {
          this.toast.error("No s'ha pogut iniciar sessió.");
        });
    },
  },
};
</script>
