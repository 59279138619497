<template>
  <div class="hidden">
    <div ref="marker">
      <div class="relative w-8 h-8 flex justify-center">
        <inline-svg
          :src="getTaxiIcon()"
          class="w-full h-full"
          :style="{ transform: `rotate(${heading}deg)` }"
        />

        <div
          class="absolute translate-y-9 px-1 py-0 text-center -space-y-1 bg-white/70 rounded-md whitespace-nowrap"
        >
          <div
            class="font-semibold"
            :class="{
              'text-emerald-500': state.code === 1,
              'text-blue-500': state.code === 2,
              'text-orange-500': state.code === 3,
              'text-red-500': state.code === 4,
              'text-gray-500': state.code === 0,
            }"
          >
            {{ taxi.code }}
          </div>
          <!-- <div>{{ formatNumber(speed, 0) }} km/h</div> -->
        </div>
      </div>
    </div>

    <div ref="popup" class="w-44 min-w-min space-y-2 font-sans">
      <div class="text-gray-800 text-sm font-bold uppercase">
        <router-link
          v-if="isAdmin"
          :to="{ name: 'taxis-edit', params: { id: taxi.id } }"
        >
          {{ taxi.code }}
        </router-link>

        <template v-else>
          {{ taxi.code }}
        </template>
      </div>
      <span>{{ taxi.state }}</span>
      <div class="space-y-1">
        <div>
          <div><translation value="status.status" /></div>
          <div
            class="text-sm font-semibold"
            :class="{
              'text-gray-500': state.code === 0,
              'text-emerald-600': state.code === 1,
              'text-blue-600': state.code === 2,
              'text-orange-500': state.code === 3,
              'text-red-500': state.code === 4,
            }"
          >
            {{ state.text }}
          </div>
        </div>
        <!--
        <div>
          <div>Velocitat</div>
          <div class="text-sm font-semibold">
             {{ formatNumber(speed, 0) }} km/h
          </div>
        </div>
        <div>
          <div>Data i hora de posicionament</div>
          <div class="text-sm font-semibold">{{ lastComm() || "-" }}</div>
          <div class="-mt-1 text-sm">{{ lastCommTimeago() || "-" }}</div>
        </div>
        -->
        <div v-if="serviceTaxi && serviceTaxi.service">
          <div><translation value="services.assigned.number" /></div>
          <div class="text-sm font-semibold">
            {{ serviceTaxi.service.service_number }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import L from "leaflet";
import InlineSvg from "vue-inline-svg";
import { mapState } from "vuex";
import Connection from "@/ws/Connection";
import Translate from "@/translations/Translate";
import Translation from "@/translations/Translation.vue";

export default {
  name: "MapTaxi",
  components: {
    InlineSvg,
    Translation,
  },
  props: ["map", "taxi", "location", "serviceTaxi", "stationTaxi"],
  emits: ["onClick"],
  data() {
    return {
      marker: null,
      heading: null,
    };
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.isAdmin,
    }),

    isDoingService() {
      return (
        this.serviceTaxi?.assignment &&
        this.serviceTaxi?.assignment.status !== "assigned" &&
        this.serviceTaxi?.assignment.status !== "accepted"
      );
    },

    isServiceAccepted() {
      return (
        this.serviceTaxi?.assignment &&
        this.serviceTaxi?.assignment.status == "accepted"
      );
    },

    speed() {
      if (this.location?.speed) {
        return (this.location.speed * 3600) / 1000;
      }

      return 0;
    },

    state() {
      if (this.taxi.state === "online" && this.isDoingService) {
        return {
          code: 3,
          text: Translate.value("taxis.state.service").text,
        };
      }

      if (this.taxi.state === "online" && this.isServiceAccepted) {
        return {
          code: 10,
          text: Translate.value("taxis.state.service").text,
        };
      }

      if (this.taxi.state === "online" && this.stationTaxi) {
        return {
          code: 2,
          text: Translate.value("taxis.state.station").text,
        };
      }

      if (this.taxi.state === "online") {
        return {
          code: 1,
          text: Translate.value("taxis.state.available").text,
        };
      }

      if (this.taxi.state === "busy") {
        return {
          code: 4,
          text: Translate.value("busy").text,
        };
      }

      return {
        code: 0,
        text: Translate.value("taxis.state.offline").text,
      };
    },
  },
  watch: {
    location: {
      deep: true,
      handler(location) {
        if (location) {
          if (this.marker) {
            this.marker.setLatLng([
              this.location.latitude,
              this.location.longitude,
            ]);
          } else {
            this.draw();
          }
        } else {
          this.undraw();
        }
      },
    },

    "location.heading": {
      immediate: true,
      handler(heading) {
        if (heading != null) {
          if (this.speed >= 1 || this.heading == null) {
            this.heading = heading;
          }
        } else {
          this.heading = 0;
        }
      },
    },
  },
  mounted() {
    if (this.location) {
      this.draw();
    }
  },
  beforeUnmount() {
    if (this.marker) {
      this.marker.remove();
    }
  },
  methods: {
    draw() {
      if (this.marker || !this.location?.longitude || !this.location?.latitude)
        return;

      const myIcon = L.divIcon({
        className: "",
        html: this.$refs.marker,
        iconAnchor: [16, 16],
        popupAnchor: [0, -16],
      });

      const marker = L.marker(
        [this.location.latitude, this.location.longitude],
        {
          icon: myIcon,
          draggable: false, //process.env.NODE_ENV === "development",
          autoPan: false, //process.env.NODE_ENV === "development",
        },
      );

      if (process.env.NODE_ENV === "development") {
        marker.on("dragend", (event) => {
          const { lat, lng } = event.target.getLatLng();

          Connection.send({
            type: "Location",
            action: "Override",
            taxiId: this.taxi.id,
            location: {
              ...this.location,
              latitude: lat,
              longitude: lng,
            },
          });
        });
      }

      marker.bindPopup(this.$refs.popup);

      marker.addTo(this.map).on("click", (e) => {
        this.$emit("onClick", this.taxi);
      });

      this.marker = marker;
    },

    undraw() {
      if (this.marker) {
        this.marker.remove();
        this.marker = null;
      }
    },

    getTaxiIcon() {
      if (this.state.code == 0) return require("@/assets/car_gray.svg");
      if (this.state.code == 3 || this.state.code == 4)
        return require("@/assets/car_red.svg");
      if (this.state.code == 1) return require("@/assets/car_green.svg");
      if (this.state.code == 10) return require("@/assets/car_orange.svg");

      return require("@/assets/car_topview.svg");
    },

    lastComm() {
      if (this.location?.timestamp)
        return this.formatTimestamp(this.location.timestamp);

      return null;
    },

    lastCommTimeago() {
      if (this.location?.timestamp)
        return this.timeago(this.location.timestamp);

      return null;
    },
  },
};
</script>
