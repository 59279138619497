<template>
  <div class="w-[95vw] max-w-3xl bg-white p-4 rounded-lg flex flex-col">
    <div class="flex justify-between items-center">
      <div>
        <h2 class="font-semibold text-gray-700">
          <translation value="map.settings.title" />
        </h2>
        <p class="text-sm text-gray-500">
          <translation value="map.settings.subtitle" />
        </p>
      </div>

      <button class="flex items-center" @click="modal.reject">
        <XIcon class="w-6 h-6 text-gray-500" />
      </button>
    </div>

    <div class="mt-2 space-y-2">
      <div>
        <label class="ml-2 flex items-center gap-2">
          <input type="checkbox" v-model="saveCurrentPosition" />

          <span class="text-gray-700">
            <translation value="map.settings.savecurrentposition" />
          </span>
        </label>
      </div>

      <div>
        <h2>
          <translation value="generic.poligons" />
        </h2>

        <label class="ml-2 flex items-center gap-2">
          <input type="checkbox" v-model="showMunicipalities" />

          <span class="text-gray-700">
            <translation value="map.settings.showMunicipalities" />
          </span>
        </label>

        <label class="ml-2 flex items-center gap-2">
          <input type="checkbox" v-model="showRoutes" />

          <span class="text-gray-700">
            <translation value="map.settings.showRoutes" />
          </span>
        </label>

        <label class="ml-2 flex items-center gap-2">
          <input type="checkbox" v-model="showAreas" />

          <span class="text-gray-700">
            <translation value="map.settings.showAreas" />
          </span>
        </label>

        <label class="ml-2 flex items-center gap-2">
          <input type="checkbox" v-model="showZones" />

          <span class="text-gray-700">
            <translation value="map.settings.showZones" />
          </span>
        </label>

        <label class="ml-2 flex items-center gap-2">
          <input type="checkbox" v-model="showSectors" />

          <span class="text-gray-700">
            <translation value="map.settings.showSectors" />
          </span>
        </label>

        <label class="ml-2 flex items-center gap-2">
          <input type="checkbox" v-model="showStations" />

          <span class="text-gray-700">
            <translation value="map.settings.showStations" />
          </span>
        </label>
        <label class="ml-2 flex items-center gap-2">
          <input type="checkbox" v-model="showServicesInProgress" />

          <span class="text-gray-700">
            <translation value="map.settings.showServicesInProgress" />
          </span>
        </label>

        <label class="ml-2 flex items-center gap-2" v-if="!isAdmin">
          <input type="checkbox" v-model="showOtherTaxis" />

          <span class="text-gray-700">
            <translation value="map.settings.showOtherTaxis" />
          </span>
        </label>
      </div>

      <div v-if="isAdmin">
        <h2>
          <translation value="generic.vehicles" />
        </h2>

        <!-- <label class="ml-2 flex items-center gap-2">
          <input type="checkbox" v-model="showActiveTaxis" />

          <span class="text-gray-700"> Mostrar taxis actius </span>
        </label>

        <label class="ml-2 flex items-center gap-2">
          <input type="checkbox" v-model="showBusyTaxis" />

          <span class="text-gray-700"> Mostrar taxis ocupats </span>
        </label> -->

        <label class="ml-2 flex items-center gap-2">
          <input type="checkbox" v-model="showOfflineTaxis" />

          <span class="text-gray-700">
            <translation value="map.settings.hideOffline" />
          </span>
        </label>

        <label class="my-4 flex flex-col items-center">
          <span class="pb-2 w-full text-gray-700">
            <translation value="generic.taxi" />:
          </span>
          <select
            class="w-full focus:ring-blue-500 focus:border-blue-500 block px-3 py-1 border-gray-300 rounded-md"
            v-model="showTaxiId"
          >
            <option :value="null">--</option>
            <option v-for="taxi in taxis" v-bind:key="taxi.id" :value="taxi.id">
              {{ taxi.code }}
            </option>
          </select>
        </label>
      </div>
    </div>

    <div class="mt-2 flex justify-end items-center">
      <htc-button
        is="button"
        color="green"
        class="font-normal px-3 h-7"
        @click="save"
      >
        <translation value="button.save" />
      </htc-button>
    </div>
  </div>
</template>

<script>
import { XIcon } from "@heroicons/vue/outline";
import HtcButton from "@/components/HtcButton.vue";
import Translation from "@/translations/Translation.vue";
import { useToast } from "vue-toastification";

export default {
  name: "MapSettings",
  components: {
    XIcon,
    HtcButton,
    Translation,
  },
  setup() {
    return {
      toast: useToast(),
    };
  },
  props: ["modal"],
  data() {
    return {
      config: { ...this.modal.payload?.config?.config },
      taxis: this.modal.payload?.taxis,
      saveCurrentPosition: false,
      isAdmin: this.$store.state.isAdmin,
    };
  },
  computed: {
    map() {
      return this.modal.payload.map;
    },

    showMunicipalities: {
      get: function () {
        return !this.config.hideMunicipalities;
      },
      set: function (newVal) {
        if (newVal) delete this.config.hideMunicipalities;
        else this.config.hideMunicipalities = true;
      },
    },

    showRoutes: {
      get: function () {
        return !this.config.hideRoutes;
      },
      set: function (newVal) {
        if (newVal) delete this.config.hideRoutes;
        else this.config.hideRoutes = true;
      },
    },

    showAreas: {
      get: function () {
        return !this.config.hideAreas;
      },
      set: function (newVal) {
        if (newVal) delete this.config.hideAreas;
        else this.config.hideAreas = true;
      },
    },

    showZones: {
      get: function () {
        return !this.config.hideZones;
      },
      set: function (newVal) {
        if (newVal) delete this.config.hideZones;
        else this.config.hideZones = true;
      },
    },

    showSectors: {
      get: function () {
        return !this.config.hideSectors;
      },
      set: function (newVal) {
        if (newVal) delete this.config.hideSectors;
        else this.config.hideSectors = true;
      },
    },

    showStations: {
      get: function () {
        return !this.config.hideStations;
      },
      set: function (newVal) {
        if (newVal) delete this.config.hideStations;
        else this.config.hideStations = true;
      },
    },

    showActiveTaxis: {
      get: function () {
        return !this.config.hideActiveTaxis;
      },
      set: function (newVal) {
        if (newVal) delete this.config.hideActiveTaxis;
        else this.config.hideActiveTaxis = true;
      },
    },

    showBusyTaxis: {
      get: function () {
        return !this.config.hideBusyTaxis;
      },
      set: function (newVal) {
        if (newVal) delete this.config.hideBusyTaxis;
        else this.config.hideBusyTaxis = true;
      },
    },

    showOfflineTaxis: {
      get: function () {
        return !this.config.showOfflineTaxis;
      },
      set: function (newVal) {
        if (newVal) delete this.config.showOfflineTaxis;
        else this.config.showOfflineTaxis = true;
      },
    },

    showTaxiId: {
      get: function () {
        return this.config.taxiId;
      },
      set: function (newVal) {
        this.config.taxiId = newVal;
      },
    },

    showServicesInProgress: {
      get: function () {
        return this.config.showServicesInProgress;
      },
      set: function (newVal) {
        if (newVal) this.config.showServicesInProgress = true;
        else delete this.config.showServicesInProgress;
      },
    },
    showOtherTaxis: {
      get: function () {
        return this.config.showOtherTaxis;
      },
      set: function (newVal) {
        if (newVal) this.config.showOtherTaxis = true;
        else delete this.config.showOtherTaxis;
      },
    },
  },
  methods: {
    save() {
      if (!this.map) return;

      let { lat: latitude, lng: longitude } = this.map.getCenter();
      let zoom = this.map.getZoom();

      if (!this.saveCurrentPosition) {
        latitude = longitude = zoom = undefined;
      }

      this.modal.resolve({
        mapConfig: {
          config: this.config,
          latitude,
          longitude,
          zoom,
        },
      });
    },
  },
};
</script>
