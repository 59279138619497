<template>
  <layout-main>
    <div class="bg-white absolute w-full h-full mx-auto z-50" v-if="modalOpen">
      <div class="w-full flex justify-between items-center p-2 border-b">
        <span>Detalls d'assignació</span>
        <button class="flex items-center" @click="closeModal">
          <XIcon class="w-6 h-6 text-gray-500" />
        </button>
      </div>
      <div class="w-full h-full p-4 overflow-auto">
        <template v-for="(ter, iter) in situation.level2Ter" v-bind:key="iter">
          <table v-if="ter.services.length && ter.taxis.length">
            <thead>
              <tr class="px-4">
                <b>
                  Matriu TER cicle {{ iter + 1 }}
                  {{ ter.priority ? "Prioritari" : "" }}
                </b>
              </tr>
            </thead>
            <body>
              <tr>
                <td class="border p-2"></td>
                <td
                  class="border p-2 bg-gray-100"
                  v-for="(t, it) in ter.taxis"
                  v-bind:key="it"
                >
                  {{ t }}
                </td>
              </tr>
              <tr class="border" v-for="(r, ir) in ter.matrix" v-bind:key="ir">
                <td class="border p-2 bg-gray-100">
                  {{ ter.services[ir] }}
                </td>
                <td
                  v-for="(c, ic) in r"
                  v-bind:key="ic"
                  :class="[
                    'border p-2',
                    inResult(ter, ir, ic)
                      ? c > minTiempoDisponible
                        ? 'bg-red-100'
                        : c > timeTerSeconds
                        ? 'bg-orange-300'
                        : 'bg-green-100'
                      : '',
                  ]"
                >
                  {{ c }}
                </td>
              </tr>
            </body>
          </table>
        </template>

        <template v-for="(ter, iter) in situation.level2Tar" v-bind:key="iter">
          <table v-if="ter.services.length && ter.taxis.length">
            <thead>
              <tr class="px-4">
                <b>
                  Matriu TAR
                  {{ ter.priority ? "Prioritari" : "" }}
                </b>
              </tr>
            </thead>
            <body>
              <tr>
                <td class="border p-2"></td>
                <td
                  class="border p-2 bg-gray-100"
                  v-for="(t, it) in ter.taxis"
                  v-bind:key="it"
                >
                  {{ t }}
                </td>
              </tr>
              <tr class="border" v-for="(r, ir) in ter.matrix" v-bind:key="ir">
                <td class="border p-2 bg-gray-100">
                  {{ ter.services[ir] }}
                </td>
                <td
                  v-for="(c, ic) in r"
                  v-bind:key="ic"
                  :class="[
                    'border p-2',
                    inResult(ter, ir, ic)
                      ? c > minTiempoDisponible
                        ? 'bg-red-100'
                        : c > timeTarSeconds
                        ? 'bg-orange-300'
                        : 'bg-green-100'
                      : '',
                  ]"
                >
                  {{ c }}
                </td>
              </tr>
            </body>
          </table>
        </template>
      </div>
    </div>

    <div class="my-5 mx-auto w-full absolute z-40 flex justify-between">
      <table class="w-[800px]">
        <thead>
          <tr>
            <th class="text-left px-2 w-[150px]">Taxi</th>
            <th class="text-left px-2 w-[150px]">Servei</th>
            <th class="text-left px-2 w-[600px]"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="px-2">
              <select
                class="w-full px-2 py-1 focus:ring-blue-500 focus:border-blue-500 block border-gray-300 rounded-md"
                v-model="selected.taxi"
              >
                <option :value="null">--</option>
                <option v-for="taxi in taxis" :key="taxi.id" :value="taxi.id">
                  {{ taxi.code }}
                </option>
              </select>
            </td>
            <td class="px-2">
              <select
                class="w-full px-2 py-1 focus:ring-blue-500 focus:border-blue-500 block border-gray-300 rounded-md"
                v-model="selected.service"
              >
                <option :value="null">--</option>
                <option
                  v-for="service in services"
                  :key="service.id"
                  :value="service.id"
                >
                  {{ service.service_number }}
                </option>
              </select>
            </td>
            <td class="px-2">
              <button
                class="mx-1 h-8 inline-flex items-center justify-center px-4 text-sm font-medium border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-default text-white bg-emerald-600 hover:bg-emerald-700 focus-visible:ring-emerald-500"
                v-on:click="drawRoute"
              >
                Calcula
              </button>

              <button
                class="mx-1 h-8 inline-flex items-center justify-center px-4 text-sm font-medium border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-default text-white bg-cyan-600 hover:bg-cyan-700 focus-visible:ring-cyan-500"
                v-on:click="drawTrips"
              >
                Mostrar tots els viatjes
              </button>

              <button
                class="mx-1 h-8 inline-flex items-center justify-center px-4 text-sm font-medium border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-default text-white bg-amber-600 hover:bg-amber-700 focus-visible:ring-amber-500"
                v-on:click="openModal"
              >
                Mostrar detalls d'assignació
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="w-60 p-2 pt-8">
        <htc-input-field
          type="datetime-local"
          min="1"
          max="4"
          v-model:value="timestamp"
          class="w-full disabled:bg-gray-200"
        />
      </div>
    </div>
    <div class="absolute z-40 bottom-0">
      <div class="w-60 p-2 max-h-[500px] overflow-y-scroll scrollbar-hide">
        <button
          class="text-sm border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-default bg-red-500 hover:bg-red-700 focus-visible:ring-red-500 text-red-50 p-1 px-4"
          v-on:click="deleteAllTrips"
          v-if="trips.length"
        >
          Esborrar tot
        </button>
        <div
          v-for="trip in trips"
          v-bind:key="trip"
          class="flex px-4 py-2 my-2 justify-between rounded bg-white"
        >
          <div>{{ trip.taxiCode }}</div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              :fill="trip.color"
            >
              <path
                d="M760-120q-39 0-70-22.5T647-200H440q-66 0-113-47t-47-113q0-66 47-113t113-47h80q33 0 56.5-23.5T600-600q0-33-23.5-56.5T520-680H313q-13 35-43.5 57.5T200-600q-50 0-85-35t-35-85q0-50 35-85t85-35q39 0 69.5 22.5T313-760h207q66 0 113 47t47 113q0 66-47 113t-113 47h-80q-33 0-56.5 23.5T360-360q0 33 23.5 56.5T440-280h207q13-35 43.5-57.5T760-360q50 0 85 35t35 85q0 50-35 85t-85 35ZM200-680q17 0 28.5-11.5T240-720q0-17-11.5-28.5T200-760q-17 0-28.5 11.5T160-720q0 17 11.5 28.5T200-680Z"
              />
            </svg>
          </div>
          <div>
            {{ trip.serviceNumber }}
            <span class="text-xs">({{ trip.duration }}s)</span>
          </div>
          <button
            class="inline-flex items-center justify-center text-sm font-medium border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-default text-red-500 hover:text-red-700 focus-visible:ring-red-500"
            v-on:click="deleteTrip(trip)"
          >
            <TrashIcon class="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>

    <div
      class="absolute w-[300px] h-[300px] mx-auto z-40 bg-white rounded top-60 right-10"
      v-if="selectedTaxi"
    >
      <div class="w-full flex justify-between items-center p-2 border-b">
        <span>Informació del taxi "{{ selectedTaxi.code }}"</span>
        <button class="flex items-center" @click="closeTaxiModal">
          <XIcon class="w-6 h-6 text-gray-500" />
        </button>
      </div>
      <div class="w-full p-4">
        <div v-if="taxiServices.length">
          <ul>
            <li>
              <b>Estat:</b>
              <translation :value="selectedTaxi.status" />
            </li>
            <li>
              <b>Final de jornada:</b>
              {{ formatHora(selectedTaxi.scheduleTo) }}
            </li>
          </ul>
        </div>
        <div v-if="taxiServices.length">
          <div class="w-full py-2 border-b font-bold">Serveis assignats:</div>
          <ul>
            <template v-for="s of taxiServices" v-bind:key="s">
              <li
                class="hover:text-sky-700 hover:cursor-pointer"
                v-on:click="() => drawTrip(selectedTaxi.id, s.id)"
              >
                {{ s.service_number }}
                <translation :value="s.status" />
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>

    <div class="relative w-full h-full">
      <div
        ref="map"
        class="z-10 w-full h-full select-none"
        v-on:click="closeTaxiModal"
      ></div>

      <template v-if="map">
        <template v-if="situation?.services">
          <map-person
            v-for="service in services"
            :key="service.id"
            :map="map"
            :service="service"
            :filters="filters"
            :config="config"
            @on-click="onServiceClick"
          />
        </template>

        <template v-if="situation?.taxisStatuses">
          <map-taxi
            v-for="taxi in taxis"
            :key="taxi.id"
            :map="map"
            :taxi="taxi"
            :location="taxi"
            :serviceTaxi="taxiService(taxi.id)"
            @on-click="onTaxiClick"
          />
        </template>
      </template>
    </div>
  </layout-main>
</template>

<script>
import LayoutMain from "@/layout/LayoutMain.vue";
import Translation from "@/translations/Translation.vue";
import MapPerson from "../../../components/Map/components/MapPerson.vue";
import MapTaxi from "../../../components/Map/components/MapTaxi.vue";
import { api } from "@/boot/axios";
import moment from "moment";
import HtcInputField from "@/components/HtcInputField.vue";
import { TrashIcon } from "@heroicons/vue/outline";
import { XIcon } from "@heroicons/vue/outline";

const L = window.L;

export default {
  name: "SituationsIndex",
  components: {
    LayoutMain,
    Translation,
    MapPerson,
    MapTaxi,
    HtcInputField,
    TrashIcon,
    XIcon,
  },
  watch: {
    timestamp: {
      handler() {
        this.fetch(() => {
          this.trips.forEach((t) => {
            this.deleteTrip(t);
          });
        });
      },
    },
  },
  data() {
    return {
      modalOpen: false,
      modalTaxiOpen: true,
      selectedTaxi: null,
      timeTer: 0,
      timeTar: 0,
      minTiempoDisponible: 0,
      trips: [],
      taxis: [],
      selected: {
        taxi: null,
        service: null,
      },
      timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
      situation: {},
      map: null,
      mapConfig: {
        latitude: 0,
        longitude: 0,
      },
      filters: {
        taxis: {
          taxisOnline: true,
          taxisAvailable: true,
          taxisInService: true,
          taxisOffline: true,
        },
        services: {
          servicesWaiting: true,
          servicesInProgress: true,
          servicesFuture: true,
        },
        routes: {},
      },
      config: {},
    };
  },
  mounted() {
    this.drawMap();
    this.fetch();
  },
  methods: {
    fetch(callback) {
      api
        .get("/situations", {
          params: {
            timestamp: moment(this.timestamp).toDate(),
          },
        })
        .then((r) => {
          this.situation = r.data.situation;
          this.timeTer = r.data.config.timeTer;
          this.timeTar = r.data.config.timeTar;
          this.minTiempoDisponible = r.data.config.minTiempoDisponible;
          this.taxis = this.situation?.taxisStatuses.sort((a, b) => {
            return a.code.localeCompare(b.code);
          });
          if (callback) callback();
        });
    },
    refreshTrips() {
      const _trips = this.trips;
      this.trips = [];
      _trips.forEach((t) => {
        this.deleteTrip(t);
        this.drawTrip(t.taxiId, t.serviceId, t.color);
      });
    },
    drawTrips() {
      this.services?.forEach((s) => {
        this.drawTrip(s.taxiId, s.id);
      });
    },
    deleteAllTrips() {
      this.trips.forEach(this.deleteTrip);
    },
    deleteTrip(trip) {
      const index = this.trips.indexOf(trip);
      this.trips = this.trips.filter((_, i) => i != index);
      trip?.polyline?.removeFrom(this.map);
      trip?.middlepoints?.map((mp) => mp.removeFrom(this.map));
    },
    drawMap() {
      if (this.map) return;

      const map = L.map(this.$refs.map, {
        center: [39.61676894764792, 3.013024034887355],
        zoom: 10,
        contextmenu: true,
        contextmenuWidth: 140,
        contextmenuItems: [
          {
            text: "Centrar el mapa",
            callback: (e) => {
              this.map?.panTo(e.latlng);
            },
          },
          {
            text: "Copiar coordenades",
            callback: (e) => {
              navigator.clipboard.writeText(e.latlng.lat + ", " + e.latlng.lng);
            },
          },
        ],
        ...(() => {
          const override = {};
          return override;
        })(),
      });

      map.getRenderer(map).options.padding = 10;

      map.zoomControl.setPosition("bottomright");

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 30,
        maxNativeZoom: 19,
        attribution:
          '&copy; OSM Mapnik <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }).addTo(map);

      map.whenReady(() => {
        this.map = map;
      });
    },
    drawRoute() {
      if (this.selected.taxi && this.selected.service)
        this.drawTrip(this.selected.taxi, this.selected.service);
    },
    drawTrip(taxiId, serviceId, _color = null, assigned = false) {
      if (
        this.trips.find((t) => t.taxiId === taxiId && t.serviceId === serviceId)
      )
        return;

      const taxi = this.situation.taxisStatuses.find((t) => t.id === taxiId);
      const service = this.situation.services.find((s) => s.id === serviceId);

      if (!(taxi && service)) return;

      const tripCoords = [[taxi.latitude, taxi.longitude]];
      this.situation.services
        .filter(
          (s) =>
            s.taxiId == taxiId &&
            moment(service.updatedAt).isAfter(s.updatedAt),
        )
        .forEach((s) => {
          if (s.status === "accepted") {
            tripCoords.push([s.pickupLat, s.pickupLng]);
            tripCoords.push([s.dropoffLat, s.dropoffLng]);
          }
          if (s.status === "pickedup") {
            tripCoords.push([s.dropoffLat, s.dropoffLng]);
          }
        });

      tripCoords.push([service.pickupLat, service.pickupLng]);
      const coords = tripCoords.map((t) => `${t[1]},${t[0]}`).join(";");
      api
        .get(`/driving/${coords}`, {
          params: {
            overview: "full",
            geometries: "geojson",
          },
        })
        .then((response) => {
          const coordinates = response.data.routes[0].geometry.coordinates;
          const distance = response.data.routes[0].distance;
          const duration = response.data.routes[0].duration;
          const color = _color ?? this.randomColor();
          const polyline = L.polyline(
            coordinates.map((c) => c.reverse()),
            {
              color,
            },
          );
          polyline.addTo(this.map);
          polyline.bindTooltip(
            `${distance}m, ${(duration / 60).toFixed(2)}min (${duration}s)`,
            { permanent: true },
          );

          const middlepoints = tripCoords.map((coords, i) => {
            const circle = L.circle(coords, {
              radius: 1,
              color: i % 2 ? "red" : "blue",
            });
            circle.addTo(this.map);
            return circle;
          });

          this.trips.push({
            taxiId: taxiId,
            serviceId: serviceId,
            taxiCode: taxi.code,
            serviceNumber: service.service_number,
            distance,
            duration,
            polyline,
            color,
            assigned,
            middlepoints,
          });
        });
    },
    randomColor() {
      const i = Math.floor(Math.random() * 3);
      const rgb = [
        Math.floor(Math.random() * 150),
        Math.floor(Math.random() * 150),
        Math.floor(Math.random() * 150),
      ];
      rgb[i] = 255;

      return `rgb(${rgb.join(" ")})`;
    },
    taxiService(taxiId) {
      const service = this.situation.services.find(
        (s) =>
          s.taxiId === taxiId && ["accepted", "pickedup"].includes(s.status),
      );
      if (!service) return null;
      return {
        assignment: {
          status: service.status,
        },
        service: service,
      };
    },
    closeModal() {
      this.modalOpen = false;
    },
    openModal() {
      this.modalOpen = true;
    },
    closeTaxiModal() {
      this.selectedTaxi = null;
    },
    inResult(ter, ir, ic) {
      return !!ter.result.find((r) => r[0] == ir && r[1] == ic);
    },
    onTaxiClick(taxi) {
      this.selectedTaxi = taxi;
    },
    onServoceClick(args) {},
    formatHora(d) {
      if (!d) return "N/A";
      return moment(d).format("DD/MM/YYYY HH:mm");
    },
  },
  computed: {
    taxiServices() {
      return this.situation?.services
        .filter((s) => s.taxiId == this.selectedTaxi.id && s.status)
        .sort((s) => (s.status == "assigned" ? -1 : 1))
        .sort((s) => (s.status == "accepted" ? -1 : 1));
    },
    services() {
      return this.situation?.services?.filter(
        (s) =>
          !["accepted", "pickedup"].includes(s.status) && s.serviceType == 0,
      );
    },
    timeTerSeconds() {
      return this.timeTer * 60;
    },
    timeTarSeconds() {
      return this.timeTar * 60;
    },
  },
};
</script>
