<template>
  <div class="w-screen">
    <div class="mt-3 w-full">
      <carousel
        :items-to-show="1"
        :autoplay="20000"
        :wrap-around="false"
        v-bind="settings"
        :breakpoints="breakpoints"
      >
        <slide v-for="notification in getNotifications()" :key="notification">
          <div class="carousel__item">
            <div class="w-full gred row justify-center">
              <ChatIcon class="w-10 h-10 mx-auto" />
            </div>
            <div class="gap-1 text-gray-800 font-semibold text-3xl">
              {{ notification }}
            </div>
          </div>
          <!--<XCircleIcon class="w-5 h-5" @click="first" />-->
        </slide>
        <template #addons>
          <navigation />
          <pagination />
        </template>
      </carousel>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Connection from "@/ws/Connection";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import Translation from "@/translations/Translation";
import Translate from "@/translations/Translate";
import { XCircleIcon, ChatIcon } from "@heroicons/vue/outline";
import moment from "moment";

export default {
  name: "ServiceInfoNotifications",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Translation,
    Translate,
    XCircleIcon,
    ChatIcon,
  },
  props: ["taxiConfig"],
  data() {
    return {
      map: null,
      editableLayer: null,

      municipalities: {},
      areas: {},
      zones: {},
      sectors: {},
      stations: {},
      services: {},
      cantares: {},
      trips: {},
      stationTaxis: {},

      mapConfig: {},
      notifications: {},
      filterZonesId: [],
      listeners: {
        ready: null,
        map: null,
      },
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 1,
          snapAlign: "start",
        },
      },
    };
  },
  computed: {
    ...mapState({
      taxi: (state) => state.taxi,
      serviceTaxi: (state) => state.serviceTaxi,
    }),

    taxiState() {
      return this.taxi?.state;
    },

    service() {
      return this.serviceTaxi?.service;
    },

    assignment() {
      return this.serviceTaxi?.assignment;
    },

    status() {
      return this.serviceTaxi?.assignment?.status;
    },
  },
  methods: {
    ...mapActions(["pickup", "dropoff"]),
    getNotifications() {
      if (
        moment(this.taxiConfig?.scheduleTo) > moment() &&
        moment(this.taxiConfig?.scheduleTo) < moment().add(15, "minutes")
      ) {
        return [Translate.value("notifications.scheduleTo").text];
      }
      /*
      console.log("this.getNot");
      console.log(this.services);
      console.log(this.taxiConfig);

      this.taxiConfig?.zones?.map((item) => {
        this.filterZonesId.push(item.value);
      });
      console.log("this.filterZonesId");
      console.log(this.filterZonesId);
      this.notifications = Object.values(this.services).filter(
        (service) =>
          moment(service.pickupAt) > moment(this.taxiConfig.noticesFrom) &&
          moment(service.pickupAt) < moment(this.taxiConfig.noticesTo),
      );
      
      console.log(this.notifications);
      */
      return [Translate.value("notifications.empty").text];
    },

    processData(msg) {
      if (this.isPropObject(msg.municipality)) {
        this.municipalities[msg.municipality.id] = msg.municipality;
      }

      if (this.isPropObject(msg.municipalities)) {
        this.municipalities = msg.municipalities;
      }

      if (typeof msg.delMunicipality === "string") {
        delete this.municipalities[msg.delMunicipality];
      }

      if (this.isPropObject(msg.area)) {
        this.areas[msg.area.id] = msg.area;
      }

      if (this.isPropObject(msg.areas)) {
        this.areas = msg.areas;
      }

      if (typeof msg.delArea === "string") {
        delete this.areas[msg.delArea];
      }

      if (this.isPropObject(msg.zone)) {
        this.zones[msg.zone.id] = msg.zone;
      }

      if (this.isPropObject(msg.zones)) {
        this.zones = msg.zones;
      }

      if (typeof msg.delZone === "string") {
        delete this.zones[msg.delZone];
      }

      if (this.isPropObject(msg.sector)) {
        this.sectors[msg.sector.id] = msg.sector;
      }

      if (this.isPropObject(msg.sectors)) {
        this.sectors = msg.sectors;
      }

      if (typeof msg.delSector === "string") {
        delete this.sectors[msg.delSector];
      }

      if (this.isPropObject(msg.station)) {
        this.stations[msg.station.id] = msg.station;
      }

      if (this.isPropObject(msg.stations)) {
        this.stations = msg.stations;
      }

      if (typeof msg.delStation === "string") {
        delete this.stations[msg.delStation];
      }

      if (this.isPropObject(msg.service)) {
        this.services[msg.service.id] = msg.service;
      }

      if (this.isPropObject(msg.services)) {
        this.services = msg.services;
      }

      if (typeof msg.delServiceTaxi === "string") {
        delete this.services[msg.delServiceTaxi];
      }

      if (this.isPropObject(msg.cantar)) {
        this.cantares[msg.cantar.id] = msg.cantar;
      }

      if (this.isPropObject(msg.cantares)) {
        this.cantares = msg.cantares;
      }

      if (typeof msg.delCantar === "string") {
        delete this.cantares[msg.delCantar];
      }

      if (this.isPropObject(msg.trip)) {
        this.trips[msg.trip.taxi.id] = msg.trip;
      }

      if (this.isPropObject(msg.trips)) {
        this.trips = msg.trips;
      }

      if (typeof msg.delTrip === "string") {
        delete this.trips[msg.delTrip];
      }

      if (this.isPropObject(msg.stationTaxi)) {
        this.stationTaxis[msg.stationTaxi.taxiId] = msg.stationTaxi;
      }

      if (this.isPropObject(msg.stationTaxis)) {
        this.stationTaxis = msg.stationTaxis;
      }

      if (typeof msg.delStationTaxi === "string") {
        delete this.stationTaxis[msg.delStationTaxi];
      }
    },
  },
  created() {
    this.listeners.ready = Connection.addListener("ready", () => {
      Connection.send({
        type: "Map",
        action: "Subscribe",
      });
    });

    this.listeners.map = Connection.addListener(
      { type: "Map", action: "Data" },
      (msg) => {
        this.processData(msg);
      },
    );

    this.getNotifications();
  },
  beforeUnmount() {
    Connection.removeListener(this.listeners.ready);
    Connection.removeListener(this.listeners.map);

    if (Connection.isReady()) {
      Connection.send({
        type: "Map",
        action: "Unsubscribe",
      });
    }
  },
};
</script>
